import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subscription, take, timer} from 'rxjs';
import {IOrder} from 'src/app/interfaces/general/profile-definitions/order.interface';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {isEqual} from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class CheckoutOrderService implements OnDestroy {
    private readonly orderStateSrc$ = new ReplaySubject<any>(1)
    public readonly state$ = this.orderStateSrc$.asObservable();
    
    private readonly order$ = timer(0, 500).pipe(
        map(() => {
            const orderStateToken = 'InjectionToken OrderStateService';
            const providersHolder = 'dsPlatformProvidersHolder';
            if (window[providersHolder]?.get(orderStateToken)) {
                return window[providersHolder].get(orderStateToken) as {
                    state$: Observable<{data: IOrder | null}>;
                };
            } else {
                return undefined;
            }
        }),
        filter(Boolean),
        take(1),
        switchMap((service) => service.state$),
        map((state) => state),
        distinctUntilChanged(isEqual),
    );

    private subscription: Subscription;

    constructor(
        private ngZone: NgZone,
    ) {
        this.subscription = this.order$.subscribe((order) => {
            this.ngZone.run(() => {
                this.orderStateSrc$.next(order);
            });
        });
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.orderStateSrc$.complete();
    }
}
