import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IOrder} from 'src/app/interfaces/general/profile-definitions/order.interface';
import {OrdersService} from 'src/app/modules/orders/orders.service';
import {GetOrderById} from './orders-item.actions';
import {firstValueFrom} from 'rxjs';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';

export interface IOrdersItemState {
    order: IOrder;
    loading: boolean;
    hasValue: boolean;
    error: any;
}

@State<IOrdersItemState>({
    name: 'ordersItem',
    defaults: {
        order: null,
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class OrdersItemState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'OrdersItemState',
    };
    constructor(
        private ticketsService: OrdersService,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static order(state: IOrdersItemState): IOrder {
        return state.order;
    }

    @Selector()
    public static loading(state: IOrdersItemState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IOrdersItemState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: IOrdersItemState): any {
        return state.error;
    }

    @Action(GetOrderById)
    public async getOrderById(
        ctx: StateContext<IOrdersItemState>,
        {id}: GetOrderById
    ): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const order: IOrder = await firstValueFrom(this.ticketsService.getOrderById(id));
            ctx.patchState({
                order,
                hasValue: !!order,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: error.error ?? error.message ?? error,
            });
        }
    }
}
