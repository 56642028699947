import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    public opened = new BehaviorSubject<boolean>(false);
    public menuData = new BehaviorSubject<{mode?: 'menu' | 'search' | 'desc'; text?: string}>({});

    constructor() {}

    public openMenu(mode?: 'menu' | 'search' | 'desc', text?: string): void {
        this.menuData.next({mode, text});
        this.opened.next(true);
    }
}
