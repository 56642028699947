import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-switcher',
    templateUrl: './switcher.component.html',
    styleUrls: ['./switcher.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitcherComponent),
            multi: true,
        },
    ],
})
export class SwitcherComponent implements ControlValueAccessor {
    @Input() public label: string = '';
    @Input() public required: boolean = false;
    @Input() public readonly: boolean = false;
    @Input() public requiredInfo: string = '';

    public disabled: boolean = false;
    public val: boolean = false;

    constructor() {
    }

    public onChange: any = () => {
    }
    public onTouch: any = () => {
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public setValue(): void {
        if (!this.readonly) {
            this.val = !this.val;
        }
    }
}

