import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataDescriptionComponent} from './data-description.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';



@NgModule({
    declarations: [DataDescriptionComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
    ],
    exports: [DataDescriptionComponent],
})
export class DataDescriptionModule { }
