import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {IKnowledgeBaseItem} from 'src/app/interfaces/general/knowledge-base/knowledge-base-item.interface';

export interface IQnADocumentItem {
    id: number;
    answer: string;
    source: string;
    questions: string[];
    metadata: any[];
    context: any;
    isDocumentText?: boolean;
    score?: number;
}

export interface IQnADocuments {
    qnaDocuments: IQnADocumentItem[];
}

export interface IQnAAnswer {
    activeLearningEnabled: boolean;
    answers: IQnADocumentItem[];
}

@Injectable({
    providedIn: 'root',
})
export class KnowledgeBaseService {
    private readonly _subscriptionKey: string;
    private readonly _scoreFilter: number;

    get baseKbUrl(): string {
        const lang = this.langService.currentLang;
        return `${this.config.get('knowledgeBaseHost')}/${
            this.config.get('knowledgeBaseId')[lang]
        }`;
    }

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
        private langService: LanguageService,
    ) {
        this._scoreFilter = +config.get('scoreFilterValue');
        this._subscriptionKey = config.get('portalSubscriptionKey');
    }

    public getAnswer(question: string): Observable<IKnowledgeBaseItem> {
        const headers = new HttpHeaders().append(
            'Ocp-Apim-Subscription-Key',
            this._subscriptionKey,
        );
        return this.http
            .post<IQnAAnswer>(`${this.baseKbUrl}/generateAnswer`, {question}, {headers})
            .pipe(
                map((res) => {
                    const passedAnswers = res.answers.filter((a) => a.score >= this._scoreFilter);
                    const theMostPossibleAnswer = passedAnswers.length ? passedAnswers[0] : null;
                    let response: IKnowledgeBaseItem = null;
                    if (theMostPossibleAnswer !== null) {
                        response = qnaItem2KbItem(theMostPossibleAnswer);
                    }
                    return response;
                }),
            );
    }

    public getAll(): Observable<IKnowledgeBaseItem[]> {
        const headers = new HttpHeaders().append(
            'Ocp-Apim-Subscription-Key',
            this._subscriptionKey,
        );
        return this.http
            .get<IQnADocuments>(`${this.baseKbUrl}/Prod/qna`, {headers})
            .pipe(map((res) => res.qnaDocuments.map((doc) => qnaItem2KbItem(doc))));
    }
}

const qnaItem2KbItem = (qnaItem: IQnADocumentItem): IKnowledgeBaseItem => ({
    id: '' + qnaItem.id,
    answer: qnaItem.answer,
    question: qnaItem.questions,
    score: qnaItem.score,
});
