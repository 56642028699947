import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {MsalBroadcastService} from '@azure/msal-angular';
import {InteractionStatus} from '@azure/msal-browser';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GuestGuard {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private msalBroadcastService: MsalBroadcastService,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.msalBroadcastService.inProgress$.pipe(
            filter((status) => status === InteractionStatus.None),
            take(1),
            map(() => {
                // Redirect to home if user is authenticated
                if (!route.routeConfig.path && this.authService.loggedIn) {
                    const queryParams = route.queryParams;
                    this.router.navigate(['/home'], {queryParams});
                }
                return true;
            }),
        );
    }
}
