import {Location} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {MsalService, MSAL_INSTANCE} from '@azure/msal-angular';
import {IPublicClientApplication, RedirectRequest} from '@azure/msal-browser';
import {from, Observable} from 'rxjs';
import {LanguageService} from 'src/app/core/i18n/language.service';

export const getQueryParam = (params, url) => {
    let href = url;
    //this expression is to get the query strings
    let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
};

@Injectable()
export class ExtendedMsalService extends MsalService {

    constructor(
        @Inject(MSAL_INSTANCE) msalInstance: IPublicClientApplication,
        location: Location,
        private languageService: LanguageService
    ) {
        super(msalInstance, location);
    }

    public loginRedirect(request?: RedirectRequest): Observable<void> {
        const initExtraQueryParams = request.extraQueryParameters;
        
        const language = this.languageService.currentLang;
        const parameters: {ui_locales: string, idps?: string} = {ui_locales: language};
        const idpsParam = getQueryParam('idps', request.redirectStartPage);
        const langParam = getQueryParam('lang', request.redirectStartPage);
        if (idpsParam) {
            parameters.idps = idpsParam;
        }
        if (langParam) {
            parameters.ui_locales = langParam;
        }
        request.extraQueryParameters = {...parameters, ...(initExtraQueryParams ?? {})};
        return from(this.instance.loginRedirect(request));
    }
}