import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    inject,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from './store/app/app.state';
import {combineLatest, fromEvent, Observable, Subject} from 'rxjs';
import {delay, distinctUntilChanged, filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {Authenticate} from 'src/app/store/app/app.actions';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {GetProfile} from 'src/app/store/profile/profile.actions';
import {EmailVerificationService} from 'src/app/shared/services/email-verification.service';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {Title} from '@angular/platform-browser';
import {MainPageService} from 'src/app/shared/services/main-page.service';
import {CheckoutAppService} from 'src/app/shared/services/checkout-core/checkout-app.service';
import {IsRedirectPageService} from 'src/app/shared/services/is-redirect-page.service';
import {
    APP_RELOAD_EVENT,
    DS_VIEW_TYPE_INJECTION_TOKEN,
} from 'src/app/shared/injection-token/injection-tokens';
import {ViewTypeEnum} from 'src/app/enums/view-type.enum';

const AUTH_IN_PROGRESS_FLAG = 'dsAuthenticationInProgress';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @Select(AppState.isAuthenticated) public auth$: Observable<boolean>;

    @HostBinding('class.booking-bar') public isBookingBar = false;

    public isIframe = false;

    // public readonly menuOpen$ = this.menuService.menuOpen$;
    public readonly isMobile$ = this.windowSizeService.isMobile$;

    private readonly _isAppLoading1$ = this.authService.msalInProgress$.pipe(
        map((msalInProgress) => msalInProgress !== InteractionStatus.None),
        distinctUntilChanged(),
    );

    private readonly _isAppLoading2$ = this.router.events.pipe(
        filter(
            (e) =>
                e instanceof NavigationStart ||
                e instanceof NavigationEnd ||
                e instanceof NavigationCancel,
        ),
        map((e) => e instanceof NavigationStart),
        distinctUntilChanged(),
        startWith(false),
    );

    public readonly isAppLoading$ = combineLatest([
        this._isAppLoading1$,
        this._isAppLoading2$,
    ]).pipe(map(([isAppLoading1, isAppLoading2]) => isAppLoading1 || isAppLoading2));

    public readonly homePage$ = this.mainPageService.isPageFn$('home');
    public readonly bookingBarPage$ = this.mainPageService.isBookingPage$;
    public readonly showGreetings$ = combineLatest([this.homePage$, this.bookingBarPage$]).pipe(
        map(([homePage, bookingBarPage]): boolean => homePage || bookingBarPage),
    );
    public readonly landingPage$ = this.mainPageService.isPageFn$('landing');

    public readonly isRedirectPage$ = this.isRedirectPage.isRedirectPage$;
    public readonly isWebView: boolean;

    private readonly _destroy$ = new Subject<void>();

    private readonly appReloadEvents$ = inject(APP_RELOAD_EVENT);

    constructor(
        // public sidebar: SidebarService,
        private router: Router,
        private authService: AuthenticationService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private store: Store,
        private windowSizeService: WindowSizeService,
        // private menuService: MenuService,
        private emailVerificationService: EmailVerificationService,
        private insight: AppInsightsService,
        private title: Title,
        private elementRef: ElementRef,
        private mainPageService: MainPageService,
        private checkoutAppService: CheckoutAppService,
        private isRedirectPage: IsRedirectPageService,
        @Inject(DS_VIEW_TYPE_INJECTION_TOKEN) public readonly viewType: string | null,
    ) {
        this.isWebView = viewType === ViewTypeEnum.EMBEDDED;
    }

    public ngOnInit(): void {
        this.appReloadEvents$
            .pipe(
                tap((event) => console.log('App reload event received: ', event.detail)),
                tap(() => console.log('Reloading in 2 seconds...')),
                delay(2000),
                takeUntil(this._destroy$),
            )
            .subscribe(() => window.location.reload());
        this.isRedirectPage.setIsRedirectPage(false);
        this.title.setTitle('Swiss Youth Hostels');
        this.authService.setLogger(this.insight);

        // we always need to completely reload the full page
        // in case if it was restored from Back Forward Cache (B/F Cache).
        fromEvent(window, 'pageshow')
            .pipe(
                filter((event: PageTransitionEvent) => event.persisted),
                takeUntil(this._destroy$),
            )
            .subscribe({
                next: () => window.location.reload(),
            });

        this.isIframe = window !== window.parent && !window.opener;
        this.windowSizeService.setWidth(window.innerWidth);
        this.translateService.addLangs(this.languageService.availableLang);
        this.languageService.languageChange$.subscribe((lang) => this.translateService.use(lang));
        this.translateService.use(this.languageService.currentLang);
        if (this.authService.loggedIn) {
            this.store.dispatch(new Authenticate());
        }

        this.authService.msalInProgress$
            .pipe(
                // Filtering for all interactions to be completed
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroy$),
            )
            .subscribe(() => {
                if (this.authService.loggedIn) {
                    this.store.dispatch(new GetProfile());
                }
            });

        this.authService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS ||
                        msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
                ),
                takeUntil(this._destroy$),
            )
            .subscribe((result: EventMessage) => {
                this.store.dispatch(new Authenticate());
                if (result.eventType === EventType.LOGIN_SUCCESS) {
                    if (this.emailVerificationService.isEmailVerificationInProgress) {
                        const emailVerificationKey =
                            this.emailVerificationService.getEmailVerificationKey();
                        const emailVerificationLanguage =
                            this.emailVerificationService.getEmailVerificationLanguage();

                        this.emailVerificationService.removeEmailVerificationKey();
                        this.emailVerificationService.removeEmailVerificationLanguage();

                        this.router.navigate(['/profile/verify'], {
                            queryParams: {
                                emailVerificationKey: emailVerificationKey,
                                lang: emailVerificationLanguage,
                            },
                        });
                    }
                    if (localStorage.getItem(AUTH_IN_PROGRESS_FLAG) === 'true') {
                        this.router.navigate(['/booking'], {queryParams: {auth: 'true'}});
                    }
                }
                return result;
            });

        this.mainPageService.isBookingPage$
            .pipe(takeUntil(this._destroy$))
            .subscribe((isBookingPage) => {
                this.isBookingBar = isBookingPage;
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public ngAfterViewInit(): void {
        const element = this.elementRef.nativeElement;

        let scrollTop = element.scrollTop;
        let overflow = element.style.overflow;
        this.checkoutAppService.openCloseEvents$.pipe(takeUntil(this._destroy$)).subscribe({
            next: (customEvent) => {
                const eventTypeName = customEvent.type;

                if (eventTypeName === 'dsCheckout_open') {
                    // save current window position
                    scrollTop = element.scrollTop;
                    overflow = element.style.overflow;

                    element.style.overflow = 'hidden';
                    element.scrollTop = 0;
                }

                if (eventTypeName === 'dsCheckout_close') {
                    // restore window position
                    element.style.overflow = overflow;
                    element.scrollTop = scrollTop;
                }
            },
        });
    }

    /* public menuItemClick(e: any): void {
        this.menuService.menuItemClick(e);
    }

    public openMenu(): void {
        this.menuService.open();
    } */

    @HostListener('window:resize')
    public onResize(): void {
        this.windowSizeService.setWidth(window.innerWidth);
    }
}
