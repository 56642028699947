export enum OrderStatusEnum {
    New = 'New',
    Checkout = 'Checkout',
    Placed = 'Placed',
    Payment = 'Payment',
    Paid = 'Paid',
    InTransit = 'InTransit',
    Delivered = 'Delivered',
    Fulfilled = 'Fulfilled',
    Canceled = 'Canceled',
    Expired = 'Expired',
    Error = 'Error',
    FulfillmentError = 'FulfillmentError',
    Deleted = 'Deleted',
}
