import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {ConfigurationService} from '../../core/config/configuration.service';
import {IBusinessTrail} from '../../interfaces/general/profile-definitions/business-trail.interface';
import {ParamsEncoder} from 'src/app/core/config/params-encoder';
import {ActivitiesState, IActivitiesState} from 'src/app/store/activities/activities.state';
import {map, mergeMap, shareReplay, switchMap, toArray} from 'rxjs/operators';
import {ActivitiesType} from 'src/app/interfaces/general/activities-type.enum';
import {Store} from '@ngxs/store';
import {ProfileService} from 'src/app/modules/profile/profile.service';

export interface IBusinessTrailRequest {
    nextPartitionKey?: string;
    nextRowKey?: string;
    partnerParam?: string;
    typeParam?: string;
    inTimeline?: boolean;
}

export interface IBusinessTrailResponse {
    data: IBusinessTrail[];
    nextPartitionKey: string;
    nextRowKey: string;
}

@Injectable({
    providedIn: 'root',
})
export class ActivitiesService {
    constructor(
        private config: ConfigurationService,
        private http: HttpClient,
        private store: Store,
        private profileService: ProfileService,
    ) {}

    public getBusinessTrail(
        queryParams?: IBusinessTrailRequest,
    ): Observable<IBusinessTrailResponse> {
        let params = new HttpParams({encoder: new ParamsEncoder()});
        /**
         * TODO it can be adjusted according to OpenAPI defs (this defs are missing now 10.05.2021
         * https://developer.discover.swiss/api-details#api=discoverswiss-test-profile-api)
         */
        if (queryParams?.nextPartitionKey) {
            params = params.append('nextPartitionKey', queryParams.nextPartitionKey);
        }
        if (queryParams?.nextRowKey) {
            params = params.append('nextRowKey', queryParams.nextRowKey);
        }
        if (queryParams?.partnerParam) {
            params = params.append('partner', queryParams.partnerParam);
        }
        if (queryParams?.typeParam) {
            params = params.append('type', queryParams.typeParam);
        }
        if (typeof queryParams?.inTimeline === 'boolean') {
            params = params.append('inTimeline', queryParams.inTimeline);
        }
        return this.http.get<any>(`${this.config.get('profileApi')}/businesstrail`, {params});
    }

    public createBusinessTrail(payload: IBusinessTrail): Observable<IBusinessTrail> {
        return this.http.post<IBusinessTrail>(
            `${this.config.get('profileApi')}/businesstrail`,
            payload,
        );
    }

    public getActivitiesStateWithDetails(): Observable<IActivitiesState> {
        return this.store.select(ActivitiesState).pipe(
            switchMap((state) => {
                let activities = state.activities;
                if (activities?.length) {
                    const cache = new Map<string, Observable<any>>();
                    return from(activities).pipe(
                        mergeMap((activity: any) => {
                            if (
                                activity.title === ActivitiesType.GENERAL_TERM_ACCEPTED ||
                                activity.title === ActivitiesType.ORDERING_TERM_ACCEPTED
                            ) {
                                const termCode = activity.termCode;
                                const request$ = this.profileService
                                    .getTerms(activity.termCode)
                                    .pipe(
                                        map((resp) => {
                                            return {...activity, details: resp};
                                        }),
                                        shareReplay(1),
                                    );
                                if (!cache.has(termCode)) {
                                    cache.set(termCode, request$);
                                }
                                return cache.get(termCode);
                            } else {
                                return of(activity);
                            }
                        }),
                        toArray(),
                        map((activitiesRes) => {
                            return {
                                ...state,
                                activities: activitiesRes,
                            };
                        }),
                    );
                } else {
                    return of(state);
                }
            }),
        );
    }
}
