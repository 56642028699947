import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {GetSharedData} from '../../store/shared-data/shared-data.actions';
import {FormBuilder} from "@angular/forms";
import {SidebarService} from "../../shared/services/sidebar.service";
import {BaseComponent} from 'src/app/core/base/base.component';

@Component({
    selector: 'app-personal-data',
    templateUrl: './shared-data.component.html',
    styleUrls: ['./shared-data.component.scss']
})
export class SharedDataComponent extends BaseComponent implements OnInit {

    public isMobileDevice = false;
    public sharedDataForm = this.fb.group({
        basicData: true,
        fellowTravelers: true,
        personalInformation: true
    });

    constructor(
        protected injector: Injector,
        private fb: FormBuilder,
        private sidebar: SidebarService
    ) {
        super(injector);
    }

    public ngOnInit(): void {
        this.store.dispatch(new GetSharedData());
        this.isMobileDevice = window.innerWidth < 768;
    }

    public onDescription(desc?: string): void {
        if (this.isMobileDevice) {
            this.router.navigate([desc], {relativeTo: this.activatedRoute, queryParamsHandling: 'preserve'});
        } else {
            this.sidebar.openMenu('desc', desc);
        }
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.isMobileDevice = window.innerWidth < 768;
    }

}
