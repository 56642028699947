import {concatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';

@Injectable({
    providedIn: 'root',
})
export class PartyService {
    private readonly _partyBaseUrl: string;
    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
    ) {
        this._partyBaseUrl = this.config.get('profileApi') + '/parties';
    }

    public getAll(): Observable<ItemPayload<IParty>> {
        return this.http.get<ItemPayload<IParty>>(`${this._partyBaseUrl}?IncludeCount=true`);
    }

    public getById(id: string): Observable<IParty> {
        return this.http.get<IParty>(`${this._partyBaseUrl}/${id}`);
    }

    public create(party: Partial<IParty>): Observable<IParty> {
        return this.http.post<IParty>(this._partyBaseUrl, party);
    }

    public update(party: IParty): Observable<IParty> {
        return this.http.put<IParty>(`${this._partyBaseUrl}/${party.identifier}`, party);
    }

    public delete(id: string): Observable<any> {
        return this.http.delete<any>(`${this._partyBaseUrl}/${id}`);
    }

    public travelerGetById(travelerId: string, partyId?: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            map((party) => {
                // TODO workaround for MVP to distinguish particular user
                const index = party.member.findIndex((t) => t.identifier === travelerId);
                return index === -1 ? null : party.member[index];
            }),
        );
    }

    public travelerCreate(traveler: ITraveler, partyId?: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                if (Array.isArray(party.member)) {
                    party.member.push(traveler);
                } else {
                    party.member = [traveler];
                }
                return this.update(party);
            }),
            map((party) => party.member[0]),
        );
    }

    public travelerUpdate(traveler: ITraveler, partyId: string): Observable<ITraveler> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                const index = party.member.findIndex((t) => t.identifier === traveler.identifier);
                if (index !== -1) {
                    party.member[index] = traveler;
                } else {
                    party.member = [traveler];
                }
                return this.update(party);
            }),
            map((party) => {
                const index = party.member.findIndex((t) => t.identifier === traveler.identifier);
                return party.member[index];
            }),
        );
    }

    public travelerDelete(travelerId: string, partyId: string): Observable<any> {
        return this.getById(partyId).pipe(
            concatMap((party) => {
                const index = party.member.findIndex((t) => t.identifier === travelerId);
                if (index !== -1) {
                    party.member.splice(index, 1);
                }
                return this.update(party);
            }),
        );
    }
}
