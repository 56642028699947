import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedDataComponent} from "./shared-data.component";
import {DataDescriptionComponent} from "../../shared/components/data-description/data-description.component";

const routes: Routes = [
    {path: '', component: SharedDataComponent},
    {path: ':text', component: DataDescriptionComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedDataRoutingModule { }
