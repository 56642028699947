/* eslint-disable @typescript-eslint/no-unused-vars */
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {ISupportForm} from 'src/app/interfaces/general/support.interface';
import {
    CancelDeletionAccountRequest,
    ConfirmDeletionAccountRequest,
    GoToSupportForm,
    InitiateDeletionAccountRequest,
    SendRequest,
} from './support.actions';
import {UpdateFormValue} from '@ngxs/form-plugin';
import {GetProfile} from '../profile/profile.actions';
import {ProfileService} from '../../modules/profile/profile.service';
import {EMPTY, firstValueFrom, Observable, of, switchMap, throwError} from 'rxjs';
import {JiraManagementService} from 'src/app/shared/services/jira-management.service';
import {catchError, concatMap, map, mergeMap, tap} from 'rxjs/operators';
import {CreateInternalRecordForJiraTicket} from 'src/app/store/support-issues/support-issues.actions';
import {ProfileState} from 'src/app/store/profile/profile.state';

export interface ISupportState {
    supportForm: {model: ISupportForm};
    hasValue: boolean;
    loading: boolean;
    supportIssue: any;
}

@State<ISupportState>({
    name: 'support',
    defaults: {
        supportForm: {
            model: null,
        },
        loading: false,
        hasValue: false,
        supportIssue: null,
    },
})
@Injectable()
export class SupportState {
    constructor(
        private store: Store,
        private profileService: ProfileService,
        private jiraService: JiraManagementService
    ) {}

    @Selector()
    public static model(state: ISupportState): ISupportForm {
        return state.supportForm.model;
    }

    @Selector()
    public static loading(state: ISupportState): boolean {
        return state.loading;
    }

    @Selector()
    public static supportIssue(state: ISupportState): any {
        return state.supportIssue;
    }

    @Action(GoToSupportForm)
    public async goToSupportForm(ctx: StateContext<ISupportState>): Promise<any> {
        ctx.patchState({loading: true, supportIssue: null, hasValue: false});
        const {profile} = await firstValueFrom(this.store.dispatch(new GetProfile()));
        ctx.patchState({loading: false});
        this.store.dispatch(
            new UpdateFormValue({
                path: 'support.supportForm',
                value: {
                    email: profile.profile.email,
                },
                propertyPath: '',
            })
        );
    }

    @Action(SendRequest)
    public sendRequest(ctx: StateContext<ISupportState>): Observable<any> {
        const state = ctx.getState();
        const form = state.supportForm.model;

        const email = form.email;
        const summary = form.title;
        const description = form.description;
        const attachments = form.attachment;

        return this.jiraService.getCustomer(email).pipe(
            tap(() => ctx.patchState({loading: true})),
            mergeMap((result) => {
                let accountId = result.size > 0 ? result.values[0].accountId : null;
                return accountId
                    ? of(accountId)
                    : this.profileService.getProfile().pipe(
                          concatMap((profile) =>
                              this.jiraService.createCustomer(profile.displayName, profile.email)
                          ),
                          map((newCustomer) => newCustomer.accountId)
                      );
            }),
            catchError(() => {
                console.error('Can not get or create account for creating ticket');
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            concatMap((accountId) => {
                const {profileId} = this.store.selectSnapshot(ProfileState.profile);
                return this.jiraService.createRequest(accountId, {summary, description, profileId});
            }),
            catchError(() => {
                console.error('An error appear during creating ticket');
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            concatMap((issue) => {
                ctx.patchState({
                    supportIssue: issue,
                });
                this.store.dispatch(new CreateInternalRecordForJiraTicket(issue));
                const attachmentIds = attachments
                    .map((a) =>
                        a.temporaryAttachments.map((attachment) => attachment.temporaryAttachmentId)
                    )
                    .flat();
                return attachmentIds?.length
                    ? this.jiraService.createAttachment(issue.issueKey, attachmentIds).pipe(
                          catchError((error) => {
                              console.error(error);
                              ctx.patchState({loading: false});
                              return of(issue.issueKey);
                          }),
                          switchMap(() => issue.issueKey)
                      )
                    : of(of(issue.issueKey));
            }),
            tap(() => ctx.patchState({loading: false}))
        );
    }

    @Action(InitiateDeletionAccountRequest)
    public InitiateDeletionAccountRequest(ctx: StateContext<ISupportState>): Observable<any> {
        ctx.patchState({loading: true});
        return this.profileService.initiateDeletionProfile().pipe(
            catchError((error) => {
                console.error('Can not initiate account deletion due to error: ', error);
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            tap(() => {
                ctx.patchState({loading: false});
            })
        );
    }

    @Action(CancelDeletionAccountRequest)
    public CancelDeletionAccountRequest(ctx: StateContext<ISupportState>): Observable<any> {
        ctx.patchState({loading: true});
        return this.profileService.cancelDeletionProfile().pipe(
            catchError((error) => {
                console.error('Can not cancel account deletion due to error: ', error);
                ctx.patchState({loading: false});
                return throwError(() => error);
            }),
            tap(() => {
                ctx.patchState({loading: false});
                this.store.dispatch(new GetProfile({forceRefetch: true}));
            })
        );
    }

    @Action(ConfirmDeletionAccountRequest)
    public ConfirmDeletionAccountRequest(ctx: StateContext<ISupportState>): Observable<any> {
        ctx.patchState({loading: true});
        return this.profileService.confirmDeletionProfile().pipe(
            catchError((error) => {
                console.error('Can not confirm account deletion due to error: ', error);
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            tap(() => {
                ctx.patchState({loading: false});
                this.store.dispatch(new GetProfile({forceRefetch: true}));
            })
        );
    }

    /* @Action(InitiateDeletionAccountRequest)
    public InitiateDeletionAccountRequest(ctx: StateContext<ISupportState>): Observable<any> {
        // const summary = 'Delete account request';
        const {email} = this.store.selectSnapshot(ProfileState.profile);
        return this.jiraService.getCustomer(email).pipe(
            tap(() => ctx.patchState({loading: true})),
            mergeMap((result) => {
                let accountId = result.size > 0 ? result.values[0].accountId : null;
                return accountId ? of(accountId) : this.profileService.getProfile().pipe(
                    concatMap((profile) => this.jiraService.createCustomer(profile.displayName, profile.email)),
                    map((newCustomer) => newCustomer.accountId)
                )
            }),
            catchError((error) => {
                console.error('Can not get or create account for creating ticket');
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            concatMap((accountId) => {
                const {profileId} = this.store.selectSnapshot(ProfileState.profile);
                return this.jiraService.deleteAccountRequest(accountId, {summary, profileId})
            }),
            catchError((error) => {
                console.error('An error appear during creating ticket');
                ctx.patchState({loading: false});
                return EMPTY;
            }),
            concatMap((issue) => {
                ctx.patchState({
                    supportIssue: issue,
                });
                return of(issue)
            }),
            concatMap((issue) => {
                return forkJoin([
                    of(issue),
                    this.profileService.deleteProfile().pipe(catchError((error) => {
                        console.error(error);
                        return EMPTY;
                    }))
                ]);
            }),
            tap(([issue, profile]) => {
                ctx.patchState({loading: false});
                if (profile) {
                    this.store.dispatch(new GetProfile());
                }
                if (issue) {
                    this.store.dispatch(new CreateInternalRecordForJiraTicket(issue));
                }
            }),
        )
    } */
}
