import {Injectable} from "@angular/core";

enum EmailVerifificationEnum {
    EmailVerificationKey = 'emailVerificationKey',
    EmailVerificationLanguage = 'emailVerificationLanguage',
}

@Injectable({
    providedIn: 'root'
})
export class EmailVerificationService {
    public get isEmailVerificationInProgress(): boolean {
        return !!sessionStorage.getItem(EmailVerifificationEnum.EmailVerificationKey);
    }

    public setEmailVerificationKey(emailVerificationKey: string): void {
        sessionStorage.setItem(EmailVerifificationEnum.EmailVerificationKey, emailVerificationKey);
    }

    public setEmailVerificationLanguage(emailVerificationLanguage: string): void {
        sessionStorage.setItem(EmailVerifificationEnum.EmailVerificationLanguage, emailVerificationLanguage);
    }

    public getEmailVerificationKey(): string {
        return sessionStorage.getItem(EmailVerifificationEnum.EmailVerificationKey);
    }

    public getEmailVerificationLanguage(): string {
        return sessionStorage.getItem(EmailVerifificationEnum.EmailVerificationLanguage);
    }

    public removeEmailVerificationKey(): void {
        sessionStorage.removeItem(EmailVerifificationEnum.EmailVerificationKey);
    }

    public removeEmailVerificationLanguage(): void {
        sessionStorage.removeItem(EmailVerifificationEnum.EmailVerificationLanguage);
    }
}
