import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExtendedMsalGuard} from './core/auth/extended-msal.guard';
import {FailedComponent} from './core/failed/failed.component';
import {GuestGuard} from './shared/guards/guest.guard';
import {ParamsHandleGuard} from 'src/app/shared/guards/params-handle.guard';
import {SjhTemplateComponent} from './core/templates/sjh-template/sjh-template.component';

const routes: Routes = [
    {
        path: 'booking',
        loadChildren: () => import('./modules/booking/routing'),
        pathMatch: 'full',
        canActivate: [ParamsHandleGuard],
    },
    {
        path: 'booking-bar',
        redirectTo: 'booking',
    },
    {
        path: 'payment-success',
        loadComponent: () =>
            import('./modules/payment-success/payment-success.component').then(
                (m) => m.PaymentSuccessComponent,
            ),
        outlet: 'payment',
    },
    {
        path: 'payment-error',
        loadComponent: () =>
            import('./modules/payment-error/payment-error.component').then(
                (m) => m.PaymentErrorComponent,
            ),
        outlet: 'payment',
    },
    {
        path: '',
        component: SjhTemplateComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/landing/landing.module').then((m) => m.LandingModule),
                canActivate: [ParamsHandleGuard, GuestGuard],
            },
            {
                path: 'account-deletion',
                loadChildren: () =>
                    import('./modules/account-deletion/account-deletion.module').then(
                        (m) => m.AccountDeletionModule,
                    ),
                canActivate: [ParamsHandleGuard, GuestGuard],
            },
            {
                path: 'order-membercard',
                loadComponent: () =>
                    import('./modules/order-membercard/order-membercard.component').then(
                        (m) => m.OrderMembercardComponent,
                    ),
                canActivate: [ParamsHandleGuard],
            },
            {
                path: 'home',
                loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
                canActivate: [ParamsHandleGuard, ExtendedMsalGuard],
            },
            {
                path: 'membership',
                loadChildren: () =>
                    import('./modules/membership/membership.module').then(
                        (m) => m.MembershipModule,
                    ),
                canActivate: [ParamsHandleGuard, ExtendedMsalGuard],
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('./modules/profile/profile.module').then((m) => m.ProfileModule),
                canActivate: [ParamsHandleGuard, ExtendedMsalGuard],
            },
            {
                path: 'newsletter-settings',
                loadChildren: () =>
                    import('./modules/newsletter-settings/newsletter-settings.module').then(
                        (m) => m.NewsletterSettingsModule,
                    ),
                canActivate: [ParamsHandleGuard, ExtendedMsalGuard],
            },
        ],
    },
    {
        // Needed for hash routing
        path: 'error',
        redirectTo: '/',
    },
    {
        // Needed for hash routing
        path: 'state',
        redirectTo: '/home',
    },
    {
        // Needed for hash routing
        path: 'code',
        redirectTo: '/home',
    },
    {
        path: 'login-failed',
        component: FailedComponent,
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            // enableTracing: true,
            // scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
