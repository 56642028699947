import {OrderStatusEnum} from 'src/app/enums/order-status.enum';
import {IOrder} from 'src/app/interfaces/general/profile-definitions/order.interface';

const statusesForHiding = [
    OrderStatusEnum.Paid,
    OrderStatusEnum.InTransit,
    OrderStatusEnum.Delivered,
    OrderStatusEnum.Fulfilled,
    OrderStatusEnum.FulfillmentError,
    OrderStatusEnum.Canceled,
];

export function isShowShoppingCart(order: IOrder | undefined): boolean {
    if (!order) {
        return false;
    }
    const orderStatus = order?.orderStatus as OrderStatusEnum;
    const needToShowStatus = !statusesForHiding.includes(orderStatus);
    return !!order?.orderedItem?.length && needToShowStatus;
}