<form class="page-container" [formGroup]="sharedDataForm">
    <div class="page-header">
        <h4>{{'sharedData' | translate}}</h4>
        <p>{{'keepTrackInfoIsShared' | translate}}</p>
        <small><i>{{'clickOnTheIcons' | translate}}</i></small>
    </div>

    <div class="shared-data-block">
        <div class="exclamation-block">
            <i class="fal fa-exclamation-triangle"></i>
            <span>{{"sharedDataNotImplementedYet" | translate}}</span>
        </div>
    </div>


    <div class="shared-data-block">
        <h5>{{'basicData' | translate}}</h5>
        <p>{{'personalInfoIdentification' | translate}}</p>
        <div class="shared-data-block__icons" (click)="onDescription('basicData')">
            <div class="shared-data-block__icons__item">
                <i class="pi-general-data"></i>
            </div>
            <div class="shared-data-block__icons__item">
                <i class="pi-provided-data"></i>
            </div>
        </div>

        <app-switcher formControlName="basicData" [required]="true" [readonly]="true"
            [requiredInfo]="'sharingTheBasicData' | translate" [label]="'shareInfo' | translate"></app-switcher>
    </div>

    <div class="shared-data-block">
        <h5>{{'fellowTravelers' | translate}}</h5>
        <p>{{'personalInfoFellowTravelers' | translate}}</p>
        <div class="shared-data-block__icons" (click)="onDescription('fellowTravelers')">
            <div class="shared-data-block__icons__item">
                <i class="pi-general-data"></i>
            </div>
            <div class="shared-data-block__icons__item">
                <i class="pi-provided-data"></i>
            </div>
        </div>

        <app-switcher formControlName="fellowTravelers" [readonly]="true" [label]="'shareFellowTravelers' | translate">
        </app-switcher>
    </div>

    <div class="shared-data-block">
        <h5>{{'personalInformation' | translate}}</h5>
        <p>{{'furtherPersonalInfo' | translate}}</p>
        <div class="shared-data-block__icons" (click)="onDescription('personalInformation')">
            <div class="shared-data-block__icons__item">
                <i class="pi-health-data"></i>
            </div>
            <div class="shared-data-block__icons__item">
                <i class="pi-provided-data"></i>
            </div>
        </div>

        <app-switcher formControlName="personalInformation" [readonly]="true" [label]="'sharePersonalInfo' | translate">
        </app-switcher>
    </div>

    <div class="privacy-icons-info">
        <small>{{'privacyIcons' | translate}} <a href="https://privacy-icons.ch/">https://privacy-icons.ch/</a></small>
        <small>{{'findFurtherInfo' | translate}} <a href="">{{'dataProtection' | translate}}</a>.</small>
    </div>
</form>
