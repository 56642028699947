import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SharedDataRoutingModule} from './shared-data-routing.module';
import {SharedDataComponent} from './shared-data.component';
import {TranslateModule} from '@ngx-translate/core';
import {SwitcherComponent} from '../../shared/components/switcher/switcher.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DropdownModule} from "../../shared/components/dropdown/dropdown.module";


@NgModule({
    declarations: [SharedDataComponent, SwitcherComponent],
    exports: [
        SwitcherComponent,
    ],
    imports: [
        CommonModule,
        SharedDataRoutingModule,
        TranslateModule,
        FormsModule,
        DropdownModule,
        ReactiveFormsModule
    ]
})
export class SharedDataModule {
}
