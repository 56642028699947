import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectLanguageComponent} from 'src/app/core/select-language/select-language.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [SelectLanguageComponent],
    imports: [CommonModule, MatMenuModule, MatTooltipModule, TranslateModule],
    exports: [SelectLanguageComponent],
})
export class SelectLanguageModule {}
