import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
    AddMe,
    CreatePartyItem,
    CreateTravelerProfile,
    DeletePartyItem,
    EditPartyItem,
    EditTravelerProfile,
    GetPartyItem,
    GetTravelerProfile,
    GoToCreateTravelerProfile,
    GoToEditTravelerProfile,
    RemoveTravelerProfile,
    ResetPartyForm,
} from './party-item.actions';
import {PartyService} from 'src/app/modules/party/party.service';
import {IParty} from 'src/app/interfaces/general/profile-definitions/party.interface';
import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';
import {ResetForm, UpdateFormValue} from '@ngxs/form-plugin';
import {Navigate} from '@ngxs/router-plugin';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {EMPTY, firstValueFrom, Observable, of} from 'rxjs';
import {ProfileState} from 'src/app/store/profile/profile.state';
import {GetProfile} from 'src/app/store/profile/profile.actions';
import {IProfile} from 'src/app/interfaces/profile';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';

export interface IPartyItemState {
    party: IParty;
    partyForm: {
        model: Partial<IParty>;
    };
    person: ITraveler;
    personForm: {
        model: ITraveler;
    };
    loading: boolean;
    hasValue: boolean;
    error: any;
}

const EMPTY_TRAVELER: ITraveler = {
    id: '',
    identifier: '',
    salutation: '',
    nationality: '',
    birthDate: null,
    familyName: '',
    givenName: '',
    passport: '',
    additionalProperty: null,
    reduction: 'none',
    gender: '',
    country: '',
    email: '',
    telephone: '',
    profileImage: '',
    supportingDocument: [],
};

@State<IPartyItemState>({
    name: 'partyItem',
    defaults: {
        party: null,
        partyForm: {
            model: {
                name: '',
                member: [],
            },
        },
        person: null,
        personForm: {
            model: {...EMPTY_TRAVELER},
        },
        loading: false,
        hasValue: false,
        error: null,
    },
})
@Injectable()
export class PartyItemState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'PartyItemState',
    };
    constructor(
        private partyService: PartyService,
        private store: Store,
        private insights: AppInsightsService
    ) {}

    @Selector()
    public static party(state: IPartyItemState): IParty {
        return state.party;
    }

    @Selector()
    public static person(state: IPartyItemState): ITraveler {
        return state.person;
    }

    @Selector()
    public static loading(state: IPartyItemState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IPartyItemState): boolean {
        return state.hasValue;
    }

    @Action(ResetPartyForm)
    public resetPartyForm(): void {
        this.store.dispatch(new ResetForm({path: 'partyItem.partyForm'}));
    }

    @Action(GetPartyItem)
    public async getParty(ctx: StateContext<IPartyItemState>, {id}: GetPartyItem): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const party: any = await firstValueFrom(this.partyService.getById(id));
            this.store.dispatch(
                new UpdateFormValue({
                    path: 'partyItem.partyForm',
                    value: party,
                })
            );
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
    }

    @Action(CreatePartyItem)
    public async createParty(ctx: StateContext<IPartyItemState>): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const partyFormModel = ctx.getState().partyForm.model;
            const addMe = partyFormModel['addMeAsTraveler'];
            const result: Partial<IParty> = {...partyFormModel};
            if (addMe) {
                let profile = this.store.selectSnapshot(ProfileState.profile);
                if (!profile) {
                    this.store.dispatch(new GetProfile());
                    profile = await firstValueFrom(
                        this.store.select(ProfileState.profile).pipe(
                            tap(() => ctx.patchState({loading: true})),
                            filter((me) => Boolean(me)),
                            take(1)
                        )
                    );
                }
                result.member = [profileToTraveler(profile)];
            }
            const party: IParty = await firstValueFrom(this.partyService.create(result));
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                error: null,
            });
            this.store.dispatch(new Navigate([`/party/${party.identifier}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
    }

    @Action(EditPartyItem)
    public async editParty(ctx: StateContext<IPartyItemState>, {id}: EditPartyItem): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const partyFormModel = ctx.getState().partyForm.model;
            const party: IParty = await firstValueFrom(this.partyService.getById(id));
            ctx.patchState({
                party,
                hasValue: !!party,
                loading: false,
                error: null,
            });
            const forSave = {...party, ...partyFormModel};
            await firstValueFrom(this.partyService.update(forSave));
            this.store.dispatch(new Navigate([`/party/${party.identifier}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                party: null,
                hasValue: false,
                loading: false,
                error,
            });
        }
    }

    @Action(DeletePartyItem)
    public async deleteParty(
        ctx: StateContext<IPartyItemState>,
        {id}: EditPartyItem
    ): Promise<any> {
        try {
            await firstValueFrom(this.partyService.delete(id));
            ctx.patchState({party: null});
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.partyForm',
                })
            );
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            this.store.dispatch(new Navigate([`/party`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
        }
    }

    @Action(GetTravelerProfile)
    public async getTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {id, travelerId}: GetTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, error: null});

        this.store.dispatch(
            new ResetForm({
                path: 'partyItem.personForm',
            })
        );

        try {
            const party: IParty =
                ctx.getState().party || (await firstValueFrom(this.partyService.getById(id)));
            const person: ITraveler = party.member.find(
                (traveler) => traveler.identifier === travelerId
            );

            this.store.dispatch(
                new UpdateFormValue({
                    path: 'partyItem.personForm',
                    value: mapMemberToFormRepresentation(person),
                })
            );

            ctx.patchState({loading: false, person});
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }

    @Action(CreateTravelerProfile)
    public async createTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, error: false});
        const model = ctx.getState().personForm.model;
        const payload: ITraveler = {
            ...model,
        };
        try {
            await firstValueFrom(this.partyService.travelerCreate(payload, partyId));
            ctx.patchState({loading: false});
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }

    @Action(AddMe)
    public addMeToTheParty(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Observable<any> {
        return this.store.select(ProfileState.profile).pipe(
            tap(() => ctx.patchState({loading: true})),
            filter((me) => Boolean(me)),
            map((me) => profileToTraveler(me)),
            switchMap((traveler) =>
                this.partyService.travelerCreate(traveler, partyId).pipe(
                    catchError((error) => {
                        console.error(error);
                        return of(EMPTY);
                    })
                )
            ),
            tap(() => {
                this.store.dispatch(new GetPartyItem(partyId));
            })
        );
    }

    @Action(EditTravelerProfile)
    public async editTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId}: EditTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, error: null});
        const model = ctx.getState().personForm.model;
        const payload: ITraveler = {
            ...model,
        };
        try {
            await firstValueFrom(this.partyService.travelerUpdate(payload, partyId));
            ctx.patchState({loading: false});
            this.store.dispatch(
                new ResetForm({
                    path: 'partyItem.personForm',
                })
            );
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }
    @Action(RemoveTravelerProfile)
    public async removeTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {partyId, travelerId}: RemoveTravelerProfile
    ): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            await firstValueFrom(this.partyService.travelerDelete(travelerId, partyId));
            ctx.patchState({loading: false, party: null});
            this.store.dispatch(new Navigate([`/party/${partyId}`]));
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({loading: false, error});
        }
    }

    @Action(GoToEditTravelerProfile)
    public goToEditTravelerProfile(
        ctx: StateContext<IPartyItemState>,
        {member, partyId}: GoToEditTravelerProfile
    ): void {
        this.store.dispatch(
            new UpdateFormValue({
                path: 'partyItem.personForm',
                value: mapMemberToFormRepresentation(member),
            })
        );
        const travelerId = member.identifier;
        this.store.dispatch(new Navigate([`/party/${partyId}/traveler/${travelerId}/edit`]));
    }

    @Action(GoToCreateTravelerProfile)
    public goToCreateTravelerProfile(): void {
        this.store.dispatch(
            new UpdateFormValue({
                path: 'partyItem.personForm',
                value: {...EMPTY_TRAVELER},
            })
        );
    }
}

const profileToTraveler = (profile: Partial<IProfile>): ITraveler => {
    return {
        salutation: profile.salutation,
        givenName: profile.givenName,
        familyName: profile.familyName,
        birthDate: profile.birthDate as Date,
        nationality: profile.nationality,
        reduction: profile.reduction,
        passport: profile.passport,
        additionalProperty: undefined,
        country: undefined,
        email: undefined,
        gender: undefined,
        id: undefined,
        identifier: profile.profileId,
        telephone: undefined,
        profileImage: profile.profileImage,
        supportingDocument: undefined,
    };
};

const mapMemberToFormRepresentation = (member: ITraveler): ITraveler => {
    const memberSalutation = member?.salutation
        ? member.salutation[0].toUpperCase() + member.salutation.slice(1)
        : member?.salutation;
    return {
        ...member,
        salutation: memberSalutation,
    };
};
