import {ITraveler} from 'src/app/interfaces/general/profile-definitions/traveler.interface';

export class ResetPartyForm {
    public static readonly type = '[Party] Reset party form';
}
export class GetPartyItem {
    public static readonly type = '[Party] Get party item';
    constructor(public id?: string) {
    }
}

export class CreatePartyItem {
    public static readonly type = '[Party] Create party item';
}

export class EditPartyItem {
    public static readonly type = '[Party] Edit party item';
    constructor(public id: string) {
    }
}

export class DeletePartyItem {
    public static readonly type = '[Party] Delete party item';
    constructor(public id: string) {
    }
}

export class CreateTravelerProfile {
    public static readonly type = '[Party] Create traveler profile';
    constructor(public partyId: string) {
    }
}

export class AddMe {
    public static readonly type = '[Party] Add me to the party';
    constructor(public partyId: string) {
    }
}

export class EditTravelerProfile {
    public static readonly type = '[Party] Edit traveler profile';
    constructor(public partyId: string) {
    }
}

export class RemoveTravelerProfile {
    public static readonly type = '[Party] Remove traveler profile';
    constructor(public partyId: string, public travelerId: string) {
    }
}

export class GetTravelerProfile {
    public static readonly type = '[Party] Get traveler profile';
    constructor(public id: string, public travelerId: string) {
    }
}

export class GoToEditTravelerProfile {
    public static readonly type = '[Party] Go to edit traveler profile';
    constructor(public member: ITraveler, public partyId: string) {
    }
}


export class GoToCreateTravelerProfile {
    public static readonly type = '[Party] Go to create traveler profile';
}
