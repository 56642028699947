<div class="page-container">
    <h4>{{currentText | translate}}</h4>

    <div class="desc-list">
        <div class="desc-list__item" *ngFor="let item of descriptionList">
            <div class="desc-list__item__head">
                <div class="desc-list__item__icon"><i [class]="item.icon"></i></div>
                <div class="desc-list__item__title">{{item.title | translate}}</div>
            </div>
            <div class="desc-list__item__body">
                <p [innerHTML]="item.desc | translate"></p>
            </div>
        </div>
    </div>

    <div class="privacy-icons-info">
        <small>{{'privacyIcons' | translate}} <a href="https://privacy-icons.ch/">https://privacy-icons.ch/</a></small>
        <small>{{'findFurtherInfo' | translate}} <a href="">{{'dataProtection' | translate}}</a>.</small>
    </div>

    <div class="back-btn" *ngIf="isMobileDevice" [routerLink]="['..']">
        <i class="fal fa-arrow-left"></i>
    </div>
</div>
