import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
    HttpResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {
    IJiraCustomer,
    IJiraQueryPage,
} from 'src/app/interfaces/general/responses/jira-query-page.interface';
import {IJiraTempAttachments} from 'src/app/interfaces/general/responses/jira-temp-attachments.interface';

@Injectable({
    providedIn: 'root',
})
export class JiraManagementService {
    private readonly _jiraApiUrl;
    private readonly _serviceDeskId;
    private readonly _commonRequestTypeId;
    private readonly _deleteAccountRequestTypeId;
    private readonly _userProfileIdCustomField;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService,
    ) {
        this._jiraApiUrl = this.config.get('jiraManagementApiUrl');
        this._serviceDeskId = this.config.get('jiraServiceDeskId');
        this._commonRequestTypeId = this.config.get('jiraCommonRequestTypeId');
        this._deleteAccountRequestTypeId = this.config.get('jiraDeleteAccountRequestTypeId');
        this._userProfileIdCustomField = this.config.get('jiraUserProfileIdCustomField');
    }

    public getCustomer(email: string): Observable<IJiraQueryPage<IJiraCustomer>> {
        return this.http.get<IJiraQueryPage<IJiraCustomer>>(
            `${this._jiraApiUrl}/customer?query=${email}`,
        );
    }

    public createCustomer(displayName: string, email: string): Observable<IJiraCustomer> {
        const newCustomer = {displayName, email};
        return this.http.post<IJiraCustomer>(`${this._jiraApiUrl}/customer`, newCustomer);
    }

    public uploadAttachments(
        files: File[],
        uploadCb: (progress: number) => void,
    ): Observable<HttpResponse<IJiraTempAttachments> | HttpEvent<IJiraTempAttachments>> {
        const formData = new FormData();
        files.forEach((f) => formData.append('file', f));

        let headers = new HttpHeaders();
        headers = headers.append('X-Atlassian-Token', 'no-check');

        return this.http
            .post<any>(`${this._jiraApiUrl}/attachTemporaryFile`, formData, {
                reportProgress: true,
                observe: 'events',
                headers,
            })
            .pipe(uploadProgress(uploadCb));
    }

    public getRequest(jiraKey: string): Observable<any> {
        return this.http.get<any>(`${this._jiraApiUrl}/request/${jiraKey}`);
    }

    public createRequest(
        jiraAccountId: string,
        payload: {summary: string; description?: string; profileId: string},
    ): Observable<any> {
        const body = {
            serviceDeskId: this._serviceDeskId,
            requestTypeId: this._commonRequestTypeId,
            raiseOnBehalfOf: jiraAccountId,
            requestFieldValues: this._payloadConverter(payload),
        };

        return this.http.post<any>(`${this._jiraApiUrl}/request`, body);
    }

    public deleteAccountRequest(
        jiraAccountId: string,
        payload: {summary: string; description?: string; profileId: string},
    ): Observable<any> {
        const body = {
            serviceDeskId: this._serviceDeskId,
            requestTypeId: this._deleteAccountRequestTypeId,
            raiseOnBehalfOf: jiraAccountId,
            requestFieldValues: this._payloadConverter(payload),
        };

        return this.http.post<any>(`${this._jiraApiUrl}/request`, body);
    }

    public createAttachment(issueKey: string, temporaryAttachmentIds: string[]): Observable<any> {
        const payload = {
            temporaryAttachmentIds,
            public: true,
        };
        return this.http.post<any>(`${this._jiraApiUrl}/request/${issueKey}/attachment`, payload);
    }

    private _payloadConverter(payload: {
        summary: string;
        description?: string;
        profileId: string;
    }): {summary: string; description?: string} {
        const {profileId} = payload;
        delete payload.profileId;
        payload[this._userProfileIdCustomField] = profileId;
        return {...payload};
    }
}

export function uploadProgress<T>(cb: (progress: number) => void) {
    return tap((event: HttpEvent<T>) => {
        if (event.type === HttpEventType.UploadProgress) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}
