import {Component, inject} from '@angular/core';
import {CheckoutWcWrapperComponent} from '../../../checkout-wc-wrapper/checkout-wc-wrapper.component';
import {RouterOutlet} from '@angular/router';
import {AsyncPipe} from '@angular/common';
import {IsRedirectPageService} from '../../../shared/services/is-redirect-page.service';

@Component({
    selector: 'app-sjh-template',
    standalone: true,
    imports: [CheckoutWcWrapperComponent, RouterOutlet, AsyncPipe],
    templateUrl: './sjh-template.component.html',
    styleUrl: './sjh-template.component.scss',
})
export class SjhTemplateComponent {
    private readonly isRedirectPage = inject(IsRedirectPageService);

    public readonly isRedirectPage$ = this.isRedirectPage.isRedirectPage$;
}
