import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GetNextOrder, GetNextOrders, GetOrders, GetPrevOrder} from './orders.actions';
import {IOrder} from 'src/app/interfaces/general/profile-definitions/order.interface';
import {OrdersService} from 'src/app/modules/orders/orders.service';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {Navigate} from '@ngxs/router-plugin';
import {OrderStatusEnum} from 'src/app/enums/order-status.enum';
import {firstValueFrom} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {errorHandler, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';

export interface IOrdersState {
    partner?: string;
    orders: IOrder[];
    loading: boolean;
    hasValue: boolean;
    nextPageToken?: string;
    error: any,
}

@State<IOrdersState>({
    name: 'orders',
    defaults: {
        partner: '',
        orders: [],
        loading: false,
        hasValue: false,
        nextPageToken: null,
        error: null,
    },
})
@Injectable()
export class OrdersState {
    private _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'OrdersState',
    };
    constructor(
        private ordersService: OrdersService,
        private store: Store,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static orders(state: IOrdersState): IOrder[] {
        return state.orders;
    }

    @Selector()
    public static loading(state: IOrdersState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: IOrdersState): boolean {
        return !!state.orders?.length;
    }

    @Selector()
    public static hasValueInStatusDone(state: IOrdersState): boolean {
        return state.orders?.some((order) => {
            return (
                order.orderStatus === OrderStatusEnum.Canceled ||
                order.orderStatus === OrderStatusEnum.FulfillmentError ||
                order.orderStatus === OrderStatusEnum.Fulfilled
            );
        });
    }

    public static hasPrevValue(id: string) {
        return createSelector([OrdersState], (state: IOrdersState) => {
            return state.orders?.findIndex((order) => order.orderNumber === id) > 0;
        });
    }

    public static hasNextValue(id: string) {
        return createSelector([OrdersState], (state: IOrdersState) => {
            return (
                state.orders.findIndex((order) => order.orderNumber === id) <
                state.orders.length - 1
            );
        });
    }

    @Action(GetOrders)
    public async getOrders(ctx: StateContext<IOrdersState>, {partner}: GetOrders): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const orders: ItemPayload<IOrder> = await firstValueFrom(
                this.ordersService.getOrders(undefined, partner)
            );
            const filteredNonMembershipOrders = orders.data.filter((i) => i.orderedItem[0]?.ticket[0]?.additionalType !== 'Membership');
            ctx.patchState({
                partner,
                orders: filteredNonMembershipOrders,
                hasValue: !!orders.data.length,
                nextPageToken: orders.nextPageToken,
                loading: false,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error,
            });
        }
    }

    @Action(GetNextOrders)
    public async getNextOrders(ctx: StateContext<IOrdersState>): Promise<any> {
        const partner = ctx.getState().partner;
        const orders = ctx.getState().orders;
        const nextPageToken = ctx.getState().nextPageToken;
        if (nextPageToken) {
            ctx.patchState({loading: true, error: null});
            try {
                const ordersState: ItemPayload<IOrder> = await firstValueFrom(
                    this.ordersService.getOrders(nextPageToken, partner)
                );
                const filteredNonMembershipOrders = ordersState.data.filter((i) => i.orderedItem[0]?.ticket[0]?.additionalType !== 'Membership');
                ctx.patchState({
                    partner,
                    orders: [...orders, ...filteredNonMembershipOrders],
                    nextPageToken: ordersState.nextPageToken,
                    loading: false,
                });
            } catch (error) {
                errorHandler({...this._errorHandlerArgsInit, error});
                ctx.patchState({
                    loading: false,
                    error,
                });
            }
        }
    }

    @Action(GetPrevOrder)
    public getPrevOrder(ctx: StateContext<IOrdersState>, {id}: GetPrevOrder): void {
        const state = ctx.getState();
        const orders = state.orders;
        const index = orders?.findIndex((order) => order.orderNumber === id);
        if (index > 0) {
            this.store.dispatch(new Navigate(['/', 'orders', orders[index - 1].orderNumber]));
        }
    }

    @Action(GetNextOrder)
    public getNextOrder(ctx: StateContext<IOrdersState>, {id}: GetNextOrder): void {
        const state = ctx.getState();
        const orders = state.orders;
        const index = orders.findIndex((order) => order.orderNumber === id);
        if (index === orders.length - 2) {
            this.store.dispatch(new GetNextOrders());
            this.store.dispatch(new Navigate(['/', 'orders', orders[index + 1].orderNumber]));
        }

        if (index < orders.length - 2) {
            this.store.dispatch(new Navigate(['/', 'orders', orders[index + 1].orderNumber]));
        }
    }
}
