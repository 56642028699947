import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';

export class SubscriptionKeyInterceptor implements HttpInterceptor {
    constructor(private config: ConfigurationService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const subscriptionKey = this.config.get('portalSubscriptionKey');
        if (subscriptionKey) {
            req = req.clone({
                headers: req.headers.append('Ocp-Apim-Subscription-Key', subscriptionKey),
            });
        }
        return next.handle(req);
    }
}
