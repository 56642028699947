import {Injectable, inject} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {InteractionStatus} from '@azure/msal-browser';
import {
    catchError,
    defer,
    delay,
    filter,
    map,
    mergeMap,
    of,
    switchMap,
    take,
} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {isJwtTokenExpired, parseJwt} from 'src/app/core/auth/helpers/jwt.helpers';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {IdTokenLoginCounter} from 'src/app/shared/helpers/id-token-login-counter.helper';
import {ID_TOKEN_HINT_INJECTION_TOKEN} from 'src/app/shared/injection-token/injection-tokens';

@Injectable({
    providedIn: 'root',
})
export class ParamsHandleGuard  {
    private readonly idTokenHint = inject(ID_TOKEN_HINT_INJECTION_TOKEN);
    constructor(
        private authService: AuthenticationService,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private config: ConfigurationService,
        private appInsightsService: AppInsightsService,
        private router: Router,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot) {
        return this.msalBroadcastService.inProgress$.pipe(
            filter((status) => status === InteractionStatus.None),
            take(1),
            mergeMap(() => {
                const idTokenHint = this.idTokenHint;
                const isLoggedIn = this.authService.loggedIn;
                console.log('ParamsHandleGuard.canActivate():', {idTokenHint, isLoggedIn, queryParams: route.queryParams});

                this.appInsightsService.trackTrace({
                    message: 'ParamsHandleGuard.canActivate():',
                    properties: {idTokenHint, isLoggedIn},
                });

                if (!idTokenHint) return of(true);

                if (isJwtTokenExpired(idTokenHint)) {
                    console.log('idTokenHint is expired, so it is not used for login. Skipping...');
                    return of(true);
                }
                
                const loginCounter = new IdTokenLoginCounter();

                if (loginCounter.isAttemptLimitReached(this.idTokenHint)) {
                    console.log('Login with idToken reached the attempt limit, so skipping');
                    return of(true);
                }


                if (!isLoggedIn) {
                    const policy = this.config.get('idTokenPolicy');
                    const authority = this.config.get('authority');
                    const clientId = this.config.get('clientID');


                    console.log('idTokenHint is not expired, so trying to login with it...');

                    return defer(() => loginCounter.attemptLogin(idTokenHint)).pipe(
                        // failure, so redirect to the home page
                        catchError(() => {
                            return of(this.router.createUrlTree(['/']));
                        }),
                        // success, so trying to login with the id_token_hint
                        switchMap((urlTree) => {
                            if (urlTree && urlTree.root) {
                                return of(urlTree);
                            }
                            return this.msalService
                                .loginRedirect({
                                    scopes: [clientId, 'openid'],
                                    authority: `${authority}/${policy}`,
                                    extraQueryParameters: {id_token_hint: idTokenHint},
                                })
                                .pipe(
                                    // just for meeting inclusion of the return type
                                    // there will be redirect anyway
                                    map(() => true)
                                );
                        })
                    );
                }

                const {localAccountId} = this.msalService.instance.getActiveAccount();
                const parsedIdTokenHint = parseJwt(idTokenHint);
                const {oid, sub} = parsedIdTokenHint ?? {};
                const profileId = oid ?? sub;

                if (localAccountId !== profileId) {
                    const postLogoutRedirectUri = window.location.href;
                    return this.msalService.logoutRedirect({postLogoutRedirectUri}).pipe(
                        delay(1000),
                        // just for meeting inclusion of the return type
                        // there will be redirect anyway
                        map(() => true)
                    );
                }

                return of(true);
            })
        );
    }
}
