<ng-container *ngIf="(showMobileShoppingCart$ | async) === false; else backNav"></ng-container>
<section class="second-header"
         [class.fix-height]="showGreeting"
         *ngIf="(homePage$ | async) === true || (isBookingPage$ | async) === true; else backNav">
    <div class="blue" *ngIf="isWebView === false"></div>
    <div class="float" *ngIf="showGreeting">
        <div class="page-size">
            <h1 class="greeting">
                @if (isBookingPage$ | async) {
                    <span class="greeting-1">{{ 'bookingBarTitle' | translate }}</span>
                } @else {
                    @if (loggedIn) {
                        <span class="greeting-1">{{ 'greeting' | translate }} {{ name$ | async }}.</span>
                        <span class="greeting-2">{{ 'welcomeToGuestPortal' | translate }}</span>
                    } @else {
                        <span class="greeting-1">{{ 'greeting' | translate }}.</span>
                        <span class="greeting-2">{{ 'welcomeToGuestPortal' | translate }}</span>
                    }
                }
            </h1>
            <!-- <app-shopping-cart-dropdown *ngIf="(isMobile$ | async) === false"></app-shopping-cart-dropdown> -->
        </div>
    </div>
</section>

<ng-template #backNav>
    <section class="back-nav">
        @if (showBackButton$ | async) {
            <button class="borderless back-btn" (click)="back()">
                <i class="fa-kit fa-arrow-left-yh"></i>
            </button>
            <!-- <app-shopping-cart-dropdown></app-shopping-cart-dropdown> -->
        } @else {
            <span></span>
        }
    </section>
</ng-template>
