import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Authenticate} from './app.actions';

export interface IAppState {
    currentRoute: string | string[];
    previousRoute: string | string[];
    isDesktop: boolean;
    authenticated: boolean;
}

@State<IAppState>({
    name: 'app',
    defaults: {
        currentRoute: '/',
        previousRoute: '/',
        isDesktop: false,
        authenticated: false
    }
})
@Injectable()
export class AppState {

    @Selector()
    public static isAuthenticated(state: IAppState): boolean {
        return state.authenticated;
    }

    @Action(Authenticate)
    public authenticate(ctx: StateContext<IAppState>, {logout}: Authenticate): void {
        ctx.patchState({authenticated: !logout});
    }
}
