/* eslint-disable @typescript-eslint/no-unused-vars */
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {ProfileService} from '../../modules/profile/profile.service';
import {JiraManagementService} from 'src/app/shared/services/jira-management.service';
import {
    CreateInternalRecordForJiraTicket,
    GetInternalRecordsForJiraTicket,
    RemoveInternalRecordForJiraTicket,
    UpdateInternalRecordForJiraTicket,
} from 'src/app/store/support-issues/support-issues.actions';
import {EMPTY, Observable} from 'rxjs';
import {catchError, concatMap, map, switchMap, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {IJiraIssue} from 'src/app/modules/support/jira.issue.interface';
import {IPropertyValue} from 'src/app/interfaces/general/profile-definitions/property-value.interface';

export interface ISupportIssuesState {
    hasValue: boolean;
    loading: boolean;
    data: any[];
    error: any;
}

@State<ISupportIssuesState>({
    name: 'supportIssues',
    defaults: {
        loading: false,
        hasValue: false,
        data: [],
        error: null,
    },
})
@Injectable()
export class SupportIssuesState {
    constructor(
        private store: Store,
        private profileService: ProfileService,
        private jiraService: JiraManagementService,
    ) {}

    @Selector()
    public static error(state: ISupportIssuesState): any {
        return state.error;
    }

    @Selector()
    public static loading(state: ISupportIssuesState): boolean {
        return state.loading;
    }

    @Selector()
    public static data(state: ISupportIssuesState): any[] {
        return state.data;
    }

    @Action(GetInternalRecordsForJiraTicket)
    public getInternalRecords(ctx: StateContext<ISupportIssuesState>): Observable<any> {
        ctx.patchState({loading: true});
        const partnerDataName = 'jiraIssues';

        return this.profileService.getPartnerDataByName(partnerDataName).pipe(
            catchError((error) => {
                ctx.patchState({error, loading: false});
                console.error(error);
                return EMPTY;
            }),
            map((partnerData) => partnerData?.additionalProperty ?? []),
            map((rawIssues) => rawIssues?.map((issue) => stringifiedPropValueToPropValue(issue))),
            tap((issues) => {
                ctx.patchState({loading: false, data: issues, hasValue: issues?.length > 0});
            }),
        );
    }

    @Action(CreateInternalRecordForJiraTicket)
    public createInternalRecord(
        ctx: StateContext<ISupportIssuesState>,
        {jiraIssue}: any,
    ): Observable<any> {
        ctx.patchState({loading: true, data: [], hasValue: false});
        const partnerDataName = 'jiraIssues';

        return this.profileService.getPartnerDataByName(partnerDataName).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    return this.profileService
                        .createPartnerData({
                            name: partnerDataName,
                            additionalProperty: [],
                        })
                        .pipe(
                            catchError((err) => {
                                ctx.patchState({error: err, loading: false});
                                console.error(err);
                                return EMPTY;
                            }),
                        );
                }
                ctx.patchState({error, loading: false});
                console.error(error);
                return EMPTY;
            }),
            concatMap(({additionalProperty}) => {
                additionalProperty = additionalProperty ?? [];
                const propValue = jiraIssueToPropertyValue(jiraIssue);
                additionalProperty.unshift(propValue);
                return this.profileService
                    .updatePartnerData(partnerDataName, {
                        name: partnerDataName,
                        additionalProperty,
                    })
                    .pipe(
                        catchError((error) => {
                            console.error(error);
                            ctx.patchState({loading: false, error});
                            return EMPTY;
                        }),
                    );
            }),
            tap((partnerData) => {
                const issues = partnerData.additionalProperty?.map((i) =>
                    stringifiedPropValueToPropValue(i),
                );
                ctx.patchState({loading: false, data: issues, hasValue: issues?.length > 0});
            }),
        );
    }

    @Action(UpdateInternalRecordForJiraTicket)
    public updateInternalRecord(
        ctx: StateContext<ISupportIssuesState>,
        {jiraKey}: any,
    ): Observable<any> {
        // ctx.patchState({loading: true, data: [], hasValue: false});
        const partnerDataName = 'jiraIssues';

        return this.jiraService.getRequest(jiraKey).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    this.store.dispatch(new RemoveInternalRecordForJiraTicket(jiraKey));
                }
                return EMPTY;
            }),
            switchMap((jiraIssue) => {
                const issue = jiraIssueToPropertyValue(jiraIssue);
                return this.profileService.updatePartnerDataByName(partnerDataName, issue);
            }),
            tap((partnerData) => {
                const issues = partnerData.additionalProperty?.map((i) =>
                    stringifiedPropValueToPropValue(i),
                );
                ctx.patchState({loading: false, data: issues, hasValue: issues?.length > 0});
            }),
        );
    }

    @Action(RemoveInternalRecordForJiraTicket)
    public removeInternalRecord(
        ctx: StateContext<ISupportIssuesState>,
        {jiraKey}: any,
    ): Observable<any> {
        // ctx.patchState({loading: true, data: [], hasValue: false});
        const partnerDataName = 'jiraIssues';

        return this.profileService.removePartnerDataByNameAndId(partnerDataName, jiraKey).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error(error);
                return EMPTY;
            }),
            tap((partnerData) => {
                const issues = partnerData.additionalProperty?.map((i) =>
                    stringifiedPropValueToPropValue(i),
                );
                ctx.patchState({loading: false, data: issues, hasValue: issues?.length > 0});
            }),
        );
    }
}

const jiraIssueToPropertyValue = (issue: IJiraIssue): Partial<IPropertyValue> => {
    const propValue: Partial<IPropertyValue> = {};
    propValue.propertyId = issue.issueKey;
    const summary =
        issue.requestFieldValues.filter((i) => i.fieldId === 'summary')?.[0]?.value ??
        'missing summary';
    const tempValue = {
        issueId: issue.issueId,
        status: issue.currentStatus.status,
        summary,
        createdDate: issue.createdDate.iso8601,
    };
    propValue.value = JSON.stringify(tempValue);
    return propValue;
};

const stringifiedPropValueToPropValue = (propValue: any): IPropertyValue => {
    const strValue = (propValue.value as unknown as string) ?? '{}';
    let value = {};
    try {
        value = JSON.parse(strValue);
    } catch (error) {
        console.error(error);
    }
    return {...propValue, value};
};
