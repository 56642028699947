export class GetInternalRecordsForJiraTicket {
    public static readonly type = '[Support] Get internal records';
}

export class CreateInternalRecordForJiraTicket {
    public static readonly type = '[Support] Create internal record';
    constructor(public jiraIssue: any) {}
}

export class UpdateInternalRecordForJiraTicket {
    public static readonly type = '[Support] Update internal record';
    constructor(public jiraKey: string) {}
}

export class RemoveInternalRecordForJiraTicket {
    public static readonly type = '[Support] Remove internal record';
    constructor(public jiraKey: string) {}
}
