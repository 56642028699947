import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LanguageService} from 'src/app/core/i18n/language.service';

@Injectable()
export class AcceptLangInterceptor implements HttpInterceptor {
    constructor(private langService: LanguageService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lang = this.langService.currentLang;
        if (lang) {
            request = request.clone({
                headers: request.headers.append('Accept-Language', lang),
            });
        }
        return next.handle(request);
    }
}
