import {Component} from '@angular/core';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public year: number = new Date().getFullYear();
    public readonly isMobile$ = this.windowSizeService.isMobile$;

    constructor(
        private windowSizeService: WindowSizeService,
    ) { }
}
