<div class="switcher" (click)="setValue()">
    <p>{{label}} <i class="required-star" *ngIf="required">*</i></p>

    <div class="switcher__container" [class.enabled]="val">
        <div class="switcher__toggle"></div>
    </div>
</div>

<span class="required-info" *ngIf="required"><i class="required-star">*</i> {{requiredInfo}}</span>

