import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, skip} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WindowSizeService {
    private readonly _mobileBreakpoint = 768;
    private _windowSizeSource$ = new BehaviorSubject<number>(0);
    public isMobile$ = this._windowSizeSource$.pipe(
        map((width) => width < this._mobileBreakpoint),
        distinctUntilChanged(),
        shareReplay(1),
    );
    public viewSwitched$ = this.isMobile$.pipe(
        skip(1),
    )

    public setWidth(width: number): void {
        this._windowSizeSource$.next(width);
    }
}
