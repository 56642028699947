export function parseJwt(token: string): Record<string, any> {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/') ?? '';
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    let parsedValue = null;
    try {
        parsedValue = JSON.parse(jsonPayload);
    } catch (e) {
        console.error(e);
    }

    return parsedValue;
}

export function isJwtTokenExpired(token: string): boolean {
    const {exp} = parseJwt(token);
    if (!exp) return false;
    const expired = Date.now() >= exp * 1000;
    return expired;
}
