import {of} from 'rxjs';
import {IMenuItem} from 'src/app/core/menu/menu-item.interface';

const menuItems: IMenuItem[] = [
    {
        label: 'membership',
        icon: 'far fa-address-card',
        children: [
            {
                label: 'viewAllExistingMemberships',
                icon: 'far fa-long-arrow-right',
                link: ['/membership'],
            },
            {
                label: 'purchasingMembershipWithSupportForAllVariations',
                icon: 'far fa-long-arrow-right',
                link: ['/membership'],
            },
        ],
    },
    {
        label: 'profile',
        icon: 'fas fa-user',
        children: [
            {
                label: 'personalData',
                icon: 'far fa-long-arrow-right',
                link: ['/profile'],
            },
            {
                label: 'notifications',
                icon: 'far fa-long-arrow-right',
                link: ['/newsletter-settings'],
            },
            {
                label: 'userAccount',
                icon: 'far fa-long-arrow-right',
                link: ['/user-account'],
            },
        ],
    },
];

export const mainMenuItems$ = of(menuItems);