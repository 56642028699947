export class IdTokenLoginCounter {

    private readonly sessionStorageKey = 'sjhIdTokenLoginCounter';

    public attemptLogin(token: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log('attemptLogin called with token: ');
            
            const counterObj = this.getCounter();
            const sessionStorageToken = counterObj?.token ?? null;

    
            // first attempt (missing sessionStorage data or token mismatch)
            if (!counterObj || (token && token !== sessionStorageToken)) {
                this.setCounter({attemptNumber: 1, token: token});
                console.log('first attempt to login with idToken, tracking attempt number 1');
                resolve();
                return;
            }
    
            // second attempt (token match)
            if (counterObj.attemptNumber < 3) {
                this.inc(counterObj);
                console.log('another attempt to login with idToken, tracking attempt number ' + counterObj.attemptNumber);
                resolve();
                return;
            }
            
            // third attempt (token match) - remove token and call failure callback
            sessionStorage.removeItem(this.sessionStorageKey);
            console.log('Login with idToken failed, removing token from sessionStorage and calling failure callback');
            reject();
        });



    }

    public isAttemptLimitReached(token: string): boolean {
        const counterObj = this.getCounter();
        if (!counterObj) {
            return false;
        }
        if (counterObj.token !== token) {
            return false;
        }
        return counterObj.attemptNumber >= 3;
    }

    private inc(counter: sessionStorageLoginCounter): void {
        const newCounter = {...counter, attemptNumber: counter.attemptNumber + 1};
        this.setCounter(newCounter);
    }

    private getCounter(): sessionStorageLoginCounter | null {
        const counter = sessionStorage.getItem(this.sessionStorageKey);
        if (counter) {
            return JSON.parse(counter);
        }
        return null;
    }

    private setCounter(counter: sessionStorageLoginCounter): void {
        sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(counter));
    }
}



export interface sessionStorageLoginCounter {
    attemptNumber: number;
    token: string;
}