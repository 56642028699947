import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from 'src/app/core/menu/menu.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DataDescriptionModule} from 'src/app/shared/components/data-description/data-description.module';
import {CustomDropdownModule} from 'src/app/shared/components/custom-dropdown/custom-dropdown.module';
import {MenuLangSelectorComponent} from './menu-lang-selector/menu-lang-selector.component';
import {RouterModule} from '@angular/router';



@NgModule({
    declarations: [MenuComponent, MenuLangSelectorComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DataDescriptionModule,
        CustomDropdownModule,
        RouterModule,
    ],
    exports: [MenuComponent]
})
export class MenuModule { }
