export class GetOrders {
    public static readonly type = '[Orders] Get orders by partner';
    constructor(public partner?: string) {}
}

export class GetNextOrders {
    public static readonly type = '[Orders] Get next orders';
}

export class GetNextOrder {
    public static readonly type = '[Orders] Get next order';
    constructor(public id: string) {
    }
}

export class GetPrevOrder {
    public static readonly type = '[Orders] Get prev order';
    constructor(public id: string) {
    }
}

