import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

interface DataDescription {
    title: string;
    icon: string;
    desc: string;
}

const HEALTH_DATA_DESC: DataDescription = {
    title: 'healthData',
    icon: 'pi-health-data',
    desc: 'weProcessYourHealthData'
};
const PERSONAL_DATA_DESC: DataDescription = {
    title: 'releasedData',
    icon: 'pi-provided-data',
    desc: 'weProcessYourPersonalData'
};
const GENERAL_PERSONAL_DATA: DataDescription = {
    title: 'generalPersonalData',
    icon: 'pi-general-data',
    desc: 'weProcessYourGeneralPersonalData'
};
const PRODUCT_DEVELOPMENT_DATA: DataDescription = {
    title: 'productDevelopment',
    icon: 'pi-product-development',
    desc: 'weUseYourPersonalDataToDevelop'
};
const STATISTICAL_ANALYSIS_DATA: DataDescription = {
    title: 'statisticalAnalysis',
    icon: 'far fa-question-circle',
    desc: 'weUseYourAnonymizedData'
};
const DESTINATION_MARKETING_DATA: DataDescription = {
    title: 'destinationMarketing',
    icon: 'pi-marketing',
    desc: 'weUseDataForMarketing'
};

@Component({
    selector: 'app-data-description',
    templateUrl: './data-description.component.html',
    styleUrls: ['./data-description.component.scss']
})
export class DataDescriptionComponent implements OnInit {

    @Input() public text: string = '';

    private readonly descriptionTexts: { [key: string]: DataDescription[] } = {
        basicData: [GENERAL_PERSONAL_DATA, PERSONAL_DATA_DESC],
        fellowTravelers: [GENERAL_PERSONAL_DATA, PERSONAL_DATA_DESC],
        personalInformation: [HEALTH_DATA_DESC, PERSONAL_DATA_DESC],
        productDevelopment: [PRODUCT_DEVELOPMENT_DATA],
        statisticalAnalysis: [STATISTICAL_ANALYSIS_DATA],
        destinationMarketing: [DESTINATION_MARKETING_DATA]
    };
    public currentText: string = '';
    public isMobileDevice: boolean = false;
    public descriptionList: DataDescription[] = [];

    constructor(private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        if (!this.text) {
            this.currentText = this.route.snapshot.params.text;
            this.isMobileDevice = true;
        } else {
            this.currentText = this.text;
        }
        this.descriptionList = this.descriptionTexts[this.currentText];
    }

}
