import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {APP_CONFIG_INJECTION_TOKEN, APP_INIT_LOCAL_STORAGE_DATA, DS_VIEW_TYPE_INJECTION_TOKEN, ID_TOKEN_HINT_INJECTION_TOKEN} from 'src/app/shared/injection-token/injection-tokens';
import {AppQueryParamEnum} from 'src/app/enums/app-query-param.enum';

if (environment.production) {
    enableProdMode();
}

const queryParamsString = window.location.href.split('?')[1] ?? '';
const queryParams = new URLSearchParams(queryParamsString);

let dsViewType = queryParams.get(AppQueryParamEnum.VIEW_TYPE);
if (dsViewType) {
    localStorage.setItem(AppQueryParamEnum.VIEW_TYPE, dsViewType);
}
if (!dsViewType) {
    const dsViewTypePreserved = localStorage.getItem(AppQueryParamEnum.VIEW_TYPE);
    if (dsViewTypePreserved) {
        dsViewType = dsViewTypePreserved;
    }

}

const idTokenHint = queryParams.get('id_token_hint');

// TODO: for debug purposes only
// remove this line in production
const localStorageData = Object.entries(localStorage);

fetch('./assets/config.json')
    .then((res) => res.json())
    .then((res) => {
        platformBrowserDynamic([
            {
                provide: APP_CONFIG_INJECTION_TOKEN,
                useValue: res,
            },
            {
                provide: DS_VIEW_TYPE_INJECTION_TOKEN,
                useValue: dsViewType,
            },
            {
                provide: ID_TOKEN_HINT_INJECTION_TOKEN,
                useValue: idTokenHint,
            },
            {
                provide: APP_INIT_LOCAL_STORAGE_DATA,
                useValue: localStorageData,
            }
        ])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    });
